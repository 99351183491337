const TipoDocumentos = [{
        idTipoDocumento: "1",
        descripcion: "DNI - Documento Nacional de Identidad"
    },
    {
        idTipoDocumento: "2",
        descripcion: "Carnet de extranjería"
    },
    {
        idTipoDocumento: "3",
        descripcion: "Pasaporte"
    },
    {
        idTipoDocumento: "4",
        descripcion: "Documento de identidad extrajero"
    },
    {
        idTipoDocumento: "5",
        descripcion: "Boleta de inscripción militar"
    },
    {
        idTipoDocumento: "6",
        descripcion: "Libreta electoral"
    },
    {
        idTipoDocumento: "7",
        descripcion: "PTP"
    },
    {
        idTipoDocumento: "8",
        descripcion: "Cédula de identidad"
    },
    {
        idTipoDocumento: "9",
        descripcion: "Licencia de conducir"
    },
    {
        idTipoDocumento: "10",
        descripcion: "Cedula diplomática de identidad"
    }
];

export default TipoDocumentos;